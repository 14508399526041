
const calculateChartHeight = (dataCount) => {
    var minimumHeight = 400;
    var additionalHeight = 0;

    if (dataCount > 10) {
        additionalHeight = (dataCount - 10) * 15;
    }

    return minimumHeight + additionalHeight;
}

export { calculateChartHeight }